* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* .MuiPaper-root:has(.custom-select-mui) {
    left: 50% !important;
} */

.MuiList-root:has(.custom-select-mui) {
  padding: 0;
}

.mtree {
  padding: 5px 0 5px 12px !important;
  position: relative !important;
}

.mtree-open ::before {
  background: #04a1a6 !important;
  bottom: auto !important;
  content: "" !important;
  height: 8px !important;
  left: 0px !important;
  margin-top: 7px !important;
  position: absolute !important;
  right: auto !important;
  width: 8px !important;
  z-index: 1 !important;
  border-radius: 50% !important;
}

.mtree-open::after {
  border-left: 1px solid #000;
  bottom: 0;
  content: "";
  left: 4px;
  position: absolute;
  top: 0px;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #495057;
  border-collapse: collapse;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #eff2f7;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #eff2f7;
}
.table tbody + tbody {
  border-top: 2px solid #eff2f7;
}
.table-sm td,
.table-sm th {
  padding: 0.3rem;
}
.table-bordered,
.table-bordered td,
.table-bordered th {
  border: 1px solid #eff2f7;
}
.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f8f9fa;
}

.add-account .table th,
.add-account .table td {
  color: #1e1e1e;
  padding: 0.75rem;
  border: 1px solid #dee2e6;
}
.table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: rgba(0, 0, 0, 0.05);
}

.add-account .table td input {
  background: #fff;
  border: 0 !important;
  width: 100%;
  padding: 4px;
  outline: none !important;
}

.add-account .btn {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
}
